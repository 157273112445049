.p-dialog-header {
    padding: 8px;
    .p-dialog-title {
        font-size: 18px;
    }
}
.p-dialog-content {
    padding: 5px;
    overflow-x: hidden;
}
.prime-table {
    .p-datatable .p-datatable-wrapper .p-datatable-thead>tr>th {
        font-size: 12px !important;
        position: -webkit-sticky !important;
        position: sticky !important;
        text-align: left !important;
        padding: 8px !important;
        top: 0px;
    }
    
    .p-datatable.p-datatable-sm .p-datatable-wrapper .p-datatable-tbody>tr>td {
        text-align: left !important;
        font-size: 10px !important;
        overflow-y: auto !important;
        padding: 6px !important;
    }
    .breakWrapData {
        tr, td, span {
            text-wrap: wrap !important;
        }
    }
    .wrapData {
        tr, td, span {
            max-width: 120px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}

.prime-table-small {
    .p-datatable .p-datatable-wrapper .p-datatable-thead>tr>th {
        text-align: left !important;
        width: 1rem;
        font-size: 12px !important;
    }

    .p-datatable.p-datatable-sm .p-datatable-wrapper .p-datatable-tbody>tr>td {
        padding: 0.1rem !important;
        text-align: left !important;
    }
}

// Tab View SCSS
.p-tabview {
    .p-tabview-nav-container, .p-tabview-nav {
        margin-bottom: 3px !important;
        padding-left: 0px !important;
        li > a {
            text-decoration:none !important;
            font-family: StagSansApp-Book, sans-serif !important;
            font-size: 12px !important;
            color: $pn-base-color;
            border: 0px !important;
        }
        li.p-highlight .p-tabview-nav-link {
            color: $pn-highlight-color !important;
            border: 0px !important;
            font-weight: 600 !important;
            border-bottom: 1px solid $pn-highlight-color !important;
        }
    }
}
// Tab Element View SCSS
.p-element {
    .p-tabmenu-nav-container, .p-tabmenu-nav  {
        margin-bottom: 5px !important;
        padding-left: 0px !important;
        li > a {
            text-decoration:none !important;
            font-family: StagSansApp-Book, sans-serif !important;
            font-size: 12px !important;
            color: $pn-base-color;
            border: 0px !important;
        }
        li.p-highlight .p-menuitem-link {
            color: $pn-highlight-color !important;
            border: 0px !important;
            font-weight: 600 !important;
            border-bottom: 1px solid $pn-highlight-color !important;
        }
    }
}

// Dropdown CSS 
.p-dropdown-panel {
    // Search Panel CSS
    .p-dropdown-header {
        background: $white !important;
    }
    // Ul list of the dropdown
    .p-dropdown-items {
        padding: 0px !important;
        margin-bottom: 0px;
        // li list of the dropdown
        .p-dropdown-item {
            font-family: StagSansApp-Book, sans-serif !important;
            font-size: 12px !important;
        }
        // selected item of the dropdown
        .p-highlight {
            background-color: $pn-base-color;
        }
    }
    // Dropdown Label
    .p-dropdown-label {
        font-family: StagSansApp-Book, sans-serif !important;
        font-size: 11px !important;
    }
} 
.p-dropdown {
    .p-dropdown-label {
        font-family: StagSansApp-Book, sans-serif !important;
        font-size: 11px !important;
    }
}

// Multiselect dropdown SCSS

.p-multiselect-panel {
    .p-multiselect-header {
        background: $white !important;
    }
    .p-multiselect-items {
        padding: 0px !important;
        .p-multiselect-item {
            font-family: StagSansApp-Book, sans-serif !important;
            font-size: 12px !important;
            .p-focus {
                background-color: $pn-base-color;
            }
        }
        .p-highlight {
            background-color: $pn-base-color;
        }
    }
}

.p-multiselect-label {
    font-family: StagSansApp-Book, sans-serif !important;
    font-size: 12px !important;
}

// Checkbox 

.p-checkbox {
    .p-checkbox-box.p-highlight {
        background: $pn-base-color;
        border: 1px solid $white !important
    }
}

// Radio Button 
.p-radiobutton  {
    .p-radiobutton-box {
        border: 1px solid #ced4da !important;
        height: 19px !important;
        width: 19px !important;
    }
    .p-highlight {
        background-color: $pn-base-color;
    }
}

// Confirmation Dialog Styles
.p-confirm-dialog  {
    width: 22% !important;
    .p-dialog-content  {
        padding-top: 15px !important;
        padding-bottom: 15px !important;
    }
}

.p-divider-content {
    font-family: StagSansApp-Light, sans-serif !important;
    font-size: 18px !important;
}

.p-galleria-item-container {
    .p-galleria-item {
        img {
            height: 400px !important;
        }
    }
}